import React, { useEffect } from "react";
import SEO from "../components/seo";
import { useStore } from "../lib/store";

const Index: React.FC = () => {
  const { setMenuLocation } = useStore();
  useEffect(() => {
    setMenuLocation("index");
  }, []);

  return <SEO title={"Home"} />;
};

export default Index;
